






import Vue from 'vue'
import Selector from '../components/Selector.vue'

export default Vue.extend({
  name: 'Select',

  components: {
    Selector,
  },
})
